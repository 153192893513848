// Mobile menu functionality
export function initMobileMenu() {
    const mobileMenuButton = document.getElementById('mobile-menu-button');
    const mobileMenuCloseButton = document.getElementById('mobile-menu-close');
    const mobileMenu = document.getElementById('mobile-menu');
    const backdrop = document.getElementById('mobile-menu-backdrop');
    const mobileLangSelector = document.getElementById('mobile-language-selector');

    function openMobileMenu() {
        mobileMenu.classList.remove('hidden');
        document.body.style.overflow = 'hidden';
    }

    function closeMobileMenu() {
        mobileMenu.classList.add('hidden');
        document.body.style.overflow = '';
    }

    if (mobileMenuButton) {
        mobileMenuButton.addEventListener('click', openMobileMenu);
    }

    if (mobileMenuCloseButton) {
        mobileMenuCloseButton.addEventListener('click', closeMobileMenu);
    }

    if (backdrop) {
        backdrop.addEventListener('click', closeMobileMenu);
    }

    if (mobileLangSelector) {
        mobileLangSelector.addEventListener('change', function(e) {
            window.location.href = e.target.value;
        });
    }
}

document.addEventListener('DOMContentLoaded', function() {
    initMobileMenu();
});
